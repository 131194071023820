//import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
//import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import './components/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import { StoreProvide } from './Store';
import {
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom';
import { AuthProvider } from 'utils/utils';
import QRCodeRedirectScreen from 'views/QRCode/QRCodeScreen';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const router = createBrowserRouter([
  {
    path: '/redirect/qrcode',
    element: <QRCodeRedirectScreen />,
  },
  {
    path: '/r/qr',
    element: <QRCodeRedirectScreen />,
  },
  {
    path: '/*',
    element: (
      <>
        <ScrollRestoration />
        <App />
      </>
    ),
  },
]);

root.render(
  //<React.StrictMode>
  <StoreProvide>
    <HelmetProvider>
      <PayPalScriptProvider deferLoading={true}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </PayPalScriptProvider>
    </HelmetProvider>
  </StoreProvide>
  //</React.StrictMode>
);

const sendToAnalytics = ({ name, delta, id }) => {
  ReactGA.gtag('event', name, {
    event_category: 'Web Vitals',
    event_action: name,
    value: Math.round(name === 'CLS' ? delta * 1000 : delta), // CLS benötigt eine spezielle Behandlung
    event_label: id, // Optionaler Bezeichner für den Event
    non_interaction: true, // Event ist nicht durch den Nutzer direkt ausgelöst
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
